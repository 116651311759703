import { Box } from '@mui/material';
import SolutionCard from '#/components/pages/Landing/partner/solution-card';
import useLocales from '#/hooks/useLocales';

export default function Solutions() {
  const { translate } = useLocales();

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        px: {
          xs: 2,
          md: 15,
        },
        maxWidth: 1264,
        margin: '0 auto',
        alignSelf: 'center',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: '1fr 1fr 1fr',
          },
          columnGap: 10,
          rowGap: 5,
        }}
      >
        {solutions.map((solution) => (
          <SolutionCard
            key={solution.value}
            title={String(
              translate(`partnerWithUs.solutions.${solution.value}.title`)
            )}
            content={String(
              translate(`partnerWithUs.solutions.${solution.value}.content`)
            )}
          />
        ))}
      </Box>
    </Box>
  );
}

const solutions = [
  {
    value: 'pensionFund',
  },
  {
    value: 'bank',
  },
  {
    value: 'fintechPlatform',
  },
];
