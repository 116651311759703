import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Image from '#/components/shared/ui/Image';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';

const cards = [
  {
    title: 'provisionLanding.betterRegulatedLife.provisionCheck.title',
    subtitle: 'provisionLanding.betterRegulatedLife.provisionCheck.subtitle',
    image:
      'https://storage.gutgeregelt.ch/assets/main-landings/sections/provisionCheck.webp',
    buttonLabel:
      'provisionLanding.betterRegulatedLife.provisionCheck.buttonLabel',
    url: '/vorsorge',
  },
  {
    title: 'provisionLanding.betterRegulatedLife.lifeSituationCheck.title',
    subtitle:
      'provisionLanding.betterRegulatedLife.lifeSituationCheck.subtitle',
    image:
      'https://storage.gutgeregelt.ch/assets/main-landings/sections/lifeSituationCheck.webp',
    buttonLabel:
      'provisionLanding.betterRegulatedLife.lifeSituationCheck.buttonLabel',
    url: '/lebenssituations-check',
  },
];

export default function BetterRegulatedLife() {
  const { translate } = useLocales();

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        px: {
          xs: 2,
          md: 15,
        },
        maxWidth: 1264,
        margin: '0 auto',
        alignSelf: 'center',
        gap: {
          xs: 3,
          md: 10,
        },
      }}
    >
      <Typography variant="h2" textAlign="center">
        {String(translate('provisionLanding.betterRegulatedLife.title'))}
      </Typography>
      <Stack
        direction={{
          xs: 'column',
          md: 'row',
        }}
        alignItems="center"
        spacing={9}
      >
        {cards.map((card, index) => (
          <Card
            key={index}
            title={String(translate(card.title))}
            subtitle={String(translate(card.subtitle))}
            image={card.image}
            buttonLabel={String(translate(card.buttonLabel))}
            url={card.url}
          />
        ))}
      </Stack>
    </Box>
  );
}

type Props = {
  title: string;
  image: string;
  subtitle: string;
  buttonLabel: string;
  url: string;
};

const Card = ({ title, image, subtitle, buttonLabel, url }: Props) => (
  <Stack direction="column" spacing={3}>
    <Typography variant="subtitle1">{title}</Typography>
    <Image
      src={image}
      alt={title}
      sx={{
        width: {
          xs: '100%',
          md: '475.5px',
        },
      }}
    />
    <Typography color="text.secondary">{subtitle}</Typography>
    <Link component={RouterLink} to={url}>
      <Button
        variant="outlined"
        endIcon={<Iconify icon="akar-icons:arrow-right" />}
      >
        <Typography>{buttonLabel}</Typography>
      </Button>
    </Link>
  </Stack>
);
