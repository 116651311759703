import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import { usePartnerMessage } from '#/api/userQueries';

interface FormInputValues {
  occupation: string;
  email: string;
  message: string;
}

export default function TalkAboutForm() {
  const { translate } = useLocales();

  const { mutateAsync: partnerMessage } = usePartnerMessage();

  const MessageSchema = Yup.object().shape({
    occupation: Yup.string().required(
      String(translate('validations.who_are_you'))
    ),
    email: Yup.string()
      .email(String(translate('validations.user.email')))
      .required(String(translate('validations.user.email'))),
    message: Yup.string().required(
      String(translate('validations.default_message'))
    ),
  });

  const defaultValues = {
    occupation: '',
    email: '',
    message: '',
  };

  const methods = useForm<FormInputValues>({
    defaultValues,
    resolver: yupResolver(MessageSchema) as any | undefined,
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = async (data: FormInputValues) => {
    await partnerMessage(data)
      .then(() => {
        toast.success(
          String(translate('toast_notifications.success.partner_message'))
        );
        reset();
      })
      .catch(() => {
        toast.error(
          String(translate('toast_notifications.error.partner_message'))
        );
      });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card
        sx={{
          p: 2,
          width: {
            sm: '100%',
            md: '450px',
          },
        }}
      >
        <Typography variant="subtitle1">
          {String(translate('partnerWithUs.talkWithUs.talkAboutPossibilities'))}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Stack direction="column" spacing={2}>
          <RHFSelect
            name="occupation"
            label={String(translate('partnerWithUs.talkWithUs.iam'))}
          >
            {partnerTypes.map((partnerType) => (
              <MenuItem key={partnerType.value} value={partnerType.value}>
                {String(
                  translate(
                    `partnerWithUs.talkWithUs.partnerOccupations.${partnerType.label}`
                  )
                )}
              </MenuItem>
            ))}
          </RHFSelect>
          <RHFTextField
            name="email"
            label={String(translate('global.formLabels.emailAddress'))}
          />
          <RHFTextField
            name="message"
            label={String(translate('global.formLabels.message'))}
            multiline
            rows={6}
          />
        </Stack>
        <Divider sx={{ mt: 5, mb: 3 }} />
        <Stack
          sx={{
            display: 'flex',
            width: 1,
            justifyContent: 'flex-end',
          }}
        >
          <LoadingButton variant="contained" type="submit">
            <Typography>
              {String(translate('global.formLabels.submit'))}
            </Typography>
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}

const partnerTypes = [
  { value: 'bank', label: 'bank' },
  {
    value: 'insurance',
    label: 'insurance',
  },

  {
    value: 'pension_fund',
    label: 'pensionFund',
  },
  {
    value: 'trust_foundation',
    label: 'trust_foundation',
  },
  {
    value: 'media_partner',
    label: 'media',
  },
  {
    value: 'association',
    label: 'association',
  },
  {
    value: 'other',
    label: 'other',
  },
];
