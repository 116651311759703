import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Stack,
  Typography,
} from '@mui/material';
import { faqData } from '#/components/pages/Landing/landingData';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';
import useHighlightWords from '#/hooks/useHighlightWords';

type Props = {
  service: string | undefined;
};

export default function FAQ({ service }: Props) {
  const { translate } = useLocales();
  const highlightWords = useHighlightWords();

  const formatedTypography = (text: string, options?: object) =>
    String(translate(text, options));

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        px: {
          xs: 2,
          md: 15,
        },
        maxWidth: 1264,
        margin: '0 auto',
        alignSelf: 'center',
      }}
    >
      <Typography
        variant="h2"
        textAlign="center"
        data-aos="fade-down"
        sx={{
          marginY: 5,
        }}
      >
        FAQ
      </Typography>
      {faqData
        .filter((data) => data.service === service)
        .flatMap((data) => data.questions)
        .map((item, i, array) => (
          <Card
            key={i}
            sx={{
              maxWidth: '900px',
              minWidth: {
                xs: '100%',
                md: '900px',
              },
              marginY: 1,
            }}
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
          >
            <Accordion slotProps={{ transition: { unmountOnExit: true } }}>
              <AccordionSummary
                aria-controls={`panel${i}a-content`}
                id={`panel${i}a-header`}
                expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}
              >
                <Typography variant="subtitle1">
                  {String(translate(item.question))}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="column">
                  <Typography color="text.secondary">
                    {i === array.length - 2
                      ? highlightWords(
                          formatedTypography(item.answer, {
                            lastWill: String(
                              translate('global.hyperlinks.lastWill')
                            ),
                            livingWill: String(
                              translate('global.hyperlinks.livingWill')
                            ),
                            powerOfAttorney: String(
                              translate('global.hyperlinks.powerOfAttorney')
                            ),
                          }),
                          [
                            String(translate('global.hyperlinks.lastWill')),
                            String(translate('global.hyperlinks.livingWill')),
                            String(
                              translate('global.hyperlinks.powerOfAttorney')
                            ),
                          ]
                        )
                      : formatedTypography(item.answer)}
                  </Typography>
                  {item.list && (
                    <Stack direction="column" spacing={2}>
                      <Typography color="text.secondary">
                        {formatedTypography(
                          `cohabitationAgreementLanding.faq.lists.answer${
                            i + 1
                          }.title`
                        )}
                      </Typography>
                      {Array.from(Array(item.list).keys()).map((_, index) => (
                        <Typography
                          key={index}
                          variant="body2"
                          color="text.secondary"
                        >
                          {'\u2022'}{' '}
                          {formatedTypography(
                            `cohabitationAgreementLanding.faq.lists.answer${
                              i + 1
                            }.items.${index}.description`
                          )}
                        </Typography>
                      ))}
                    </Stack>
                  )}
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Card>
        ))}
    </Box>
  );
}
