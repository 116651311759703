import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '#/components/shared/ui/Page';
import Landing from '#/components/pages/Landing/Landing';
import useLocales from '#/hooks/useLocales';

type Props = {
  serviceProp?: string;
};

const Home = ({ serviceProp }: Props) => {
  const { serviceLanding } = useParams();
  const navigate = useNavigate();

  const service = serviceLanding || serviceProp;

  const { translate } = useLocales();

  let landingPageName;
  let pageDescription;

  switch (service) {
    case 'patientenverfuegung':
      landingPageName = 'metadata.living_will.title';
      break;
    case 'vorsorgeauftrag':
      landingPageName = 'metadata.power_of_attorney.title';
      break;
    case 'testament':
      landingPageName = 'metadata.last_will.title';
      break;
    case 'ehevertrag':
      landingPageName = 'metadata.marriage_contract.title';
      break;
    case 'partner-werden':
      landingPageName = 'metadata.partner_with_us.title';
      break;
    case 'digitaler-nachlass':
      landingPageName = 'metadata.digital_footprint.title';
      break;
    case 'vermoegens-und-sachregister':
      landingPageName = 'metadata.register_assets.title';
      break;
    case 'grosseltern-magazin':
      landingPageName = 'metadata.grosseItern.title';
      break;
    case 'vorsorgecheck':
      landingPageName = 'metadata.preventiveCheckUp.title';
      break;
    case 'konkubinatsvertrag':
      landingPageName = 'metadata.cohabitation_agreement.title';
      break;
    default:
      landingPageName = 'metadata.main.title';
      break;
  }

  switch (service) {
    case 'patientenverfuegung':
      pageDescription = 'metadata.living_will.description';
      break;
    case 'vorsorgeauftrag':
      pageDescription = 'metadata.power_of_attorney.description';
      break;
    case 'testament':
      pageDescription = 'metadata.last_will.description';
      break;
    case 'ehevertrag':
      pageDescription = 'metadata.marriage_contract.description';
      break;
    case 'partner-werden':
      pageDescription = 'metadata.partner_with_us.description';
      break;
    case 'digitaler-nachlass':
      pageDescription = 'metadata.digital_footprint.description';
      break;
    case 'vermoegens-und-sachregister':
      pageDescription = 'metadata.register_assets.description';
      break;
    case 'grosseltern-magazin':
      pageDescription = 'metadata.grosseItern.description';
      break;
    case 'vorsorgecheck':
      landingPageName = 'metadata.preventiveCheckUp.description';
      break;
    case 'konkubinatsvertrag':
      pageDescription = 'metadata.cohabitation_agreement.description';
      break;
    default:
      pageDescription = 'metadata.main.description';
      break;
  }

  const getServiceKey = () => {
    const serviceToI18nKeyMap = {
      '': 'main', // '': 'main
      main: 'main',
      patientenverfuegung: 'living_will',
      vorsorgeauftrag: 'power_of_attorney',
      testament: 'last_will',
      ehevertrag: 'marriage_contract',
      konkubinatsvertrag: 'cohabitation_agreement',
      'partner-werden': 'partner_with_us',
      'digitaler-nachlass': 'digital_footprint',
      'vermoegens-und-sachregister': 'register_assets',
      'grosseltern-magazin': 'grosseItern',
    };

    // @ts-expect-error
    return serviceToI18nKeyMap[service] || '';
  };

  const i18nKey = getServiceKey();

  const selectedKeywords = translate(`metadata.${i18nKey}.keywords`, {
    returnObjects: true,
  });

  const concatenateStrings = (stringsArray: string[]) =>
    stringsArray.join(', ');

  const translatedValues = Object.keys(selectedKeywords).map(
    // @ts-expect-error
    (key) => selectedKeywords[key]
  );

  const concatenatedKeywords = concatenateStrings(translatedValues);

  const isUnknown =
    service !== 'patientenverfuegung' &&
    service !== 'vorsorgeauftrag' &&
    service !== 'testament' &&
    service !== 'ehevertrag' &&
    service !== 'partner-werden' &&
    service !== 'digitaler-nachlass' &&
    service !== 'vermoegens-und-sachregister' &&
    service !== 'grosseltern-magazin' &&
    service !== 'konkubinatsvertrag';

  useEffect(() => {
    if (isUnknown) {
      navigate('/');
    }
  }, [isUnknown]);

  useEffect(() => {
    if (service === 'grosseltern-magazin') {
      navigate(`/${service}?promo=50`, { replace: true });
    }
  }, [service]);

  return (
    <Page
      title={String(translate(landingPageName))}
      description={String(translate(pageDescription))}
      keywords={concatenatedKeywords}
    >
      <Landing title={service || 'main'} />
    </Page>
  );
};

export default Home;
