import { Box, Stack, Typography } from '@mui/material';
import TalkAboutForm from '#/components/pages/Landing/partner/talk-about-form';
import useLocales from '#/hooks/useLocales';

export default function PartnerForm() {
  const { translate } = useLocales();
  return (
    <Box
      sx={{
        px: {
          xs: 2,
          md: 15,
        },
        maxWidth: 1264,
        margin: '0 auto',
        alignSelf: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        gap: {
          xs: 3,
          md: 10,
        },
      }}
      id="partner-with-us"
    >
      <TalkAboutForm />
      <Stack direction="column" spacing={3} data-aos="fade-right">
        <Typography variant="h2">
          {String(translate('partnerWithUs.talkWithUs.title'))}
        </Typography>
        <Typography variant="body1">
          {String(translate('partnerWithUs.talkWithUs.description'))}
        </Typography>
      </Stack>
    </Box>
  );
}
