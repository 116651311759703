import { Box, Typography, useTheme } from '@mui/material';
import useLocales from '#/hooks/useLocales';
import SvgIconStyle from '#/components/shared/ui/SvgIconStyle';

type WhyUsCardProps = {
  title: string;
  description: string;
  icon: string;
};

const WhyUsCard = ({ title, description, icon }: WhyUsCardProps) => {
  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
      data-aos="flip-left"
      data-aos-duration="900"
    >
      <SvgIconStyle
        src={icon}
        sx={{
          width: '50px !important',
          color: theme.palette.primary.main,
        }}
      />
      <Typography
        component="h5"
        variant="subtitle1"
        textAlign="center"
        sx={{
          height: '25px',
          marginBottom: 2,
          lineHeight: '1.2',
        }}
      >
        {String(translate(title))}
      </Typography>
      <Typography color="text.secondary" textAlign="center">
        {String(translate(description))}
      </Typography>
    </Box>
  );
};

export default WhyUsCard;
