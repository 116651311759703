import { Card, Typography } from '@mui/material';

type Props = {
  title: string;
  content: string;
};
export default function SolutionCard({ title, content }: Props) {
  return (
    <Card sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="subtitle1">{title}</Typography>
      <Typography variant="body2" color="text.secondary">
        {content}
      </Typography>
    </Card>
  );
}
