import { Box, Stack, Typography } from '@mui/material';
import {
  whatIs,
  youtubeTutorials,
} from '#/components/pages/Landing/landingData';
import WhyUsCard from '#/components/pages/Landing/Cards/WhyUsCard';
import useLocales from '#/hooks/useLocales';

type WhatIsProps = {
  service: string | undefined;
};
const WhatIs = ({ service }: WhatIsProps) => {
  const { translate } = useLocales();

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        px: {
          xs: 2,
          md: 15,
        },
        maxWidth: 1264,
        margin: '0 auto',
        alignSelf: 'center',
      }}
    >
      <Stack
        sx={{
          marginBottom: 5,
        }}
      >
        {youtubeTutorials
          .filter((tutorial) => tutorial.service === service)
          .map((t, i) => (
            <Typography
              key={i}
              variant="h2"
              textAlign="center"
              data-aos="fade-down"
            >
              {String(translate(t.title))}
            </Typography>
          ))}
      </Stack>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md:
              service === 'grosseltern-magazin'
                ? '1fr 1fr 1fr 1fr'
                : '1fr 1fr 1fr',
          },
          columnGap: 6,
          rowGap: 5,
        }}
      >
        {whatIs
          .filter((item) => item.service === service)
          .map((item, i) => (
            <WhyUsCard {...item} key={i} />
          ))}
      </Box>
    </Box>
  );
};

export default WhatIs;
