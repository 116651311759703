import { Box, Card, Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  digitalFootprintTestimonials,
  registerOfAssetsTestimonials,
  marriageContractTestimonials,
  testimonials,
} from './landingData';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';

type Props = {
  title?: string;
};

const Testimonials = ({ title }: Props) => {
  const { translate } = useLocales();

  const theme = useTheme();

  let mappedTestimonials;

  if (title === 'digitaler-nachlass') {
    mappedTestimonials = digitalFootprintTestimonials;
  } else if (title === 'vermoegens-und-sachregister') {
    mappedTestimonials = registerOfAssetsTestimonials;
  } else if (title === 'ehevertrag') {
    mappedTestimonials = marriageContractTestimonials;
  } else {
    mappedTestimonials = testimonials;
  }

  return (
    <Box
      sx={{
        px: {
          xs: 2,
          md: 15,
        },
        justifyContent: 'center',
        py: 10,
        background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: '1fr 1fr 1fr',
          },
          columnGap: 10,
          rowGap: 5,
          width: '100%',
          maxWidth: '1264px',
          margin: '0 auto',
        }}
      >
        {mappedTestimonials.map((item, i) => (
          <TestimonialsCard
            name={String(translate(item.name))}
            testimonial={String(translate(item.testimonial))}
            key={`testimonial_${i}`}
            index={i}
          />
        ))}
      </Box>
    </Box>
  );
};

type CardProps = {
  name: string;
  testimonial: string;
  index?: number;
};
const TestimonialsCard = ({ name, testimonial, index }: CardProps) => (
  <Card
    sx={{
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      minHeight: '320px',
      borderTopRightRadius: 24,
      borderTopLeftRadius: 0,
      borderBottomRightRadius: 0,
    }}
    data-aos="fade-up"
    data-aos-anchor-placement="center-bottom"
    data-aos-duration={500 * (index ? index + 1 : 1)}
  >
    <Stack direction="row" alignItems="center">
      <Typography variant="subtitle1">{name}</Typography>
      <Iconify icon="noto:star" />
      <Iconify icon="noto:star" />
      <Iconify icon="noto:star" />
      <Iconify icon="noto:star" />
    </Stack>
    <Typography>{testimonial}</Typography>
  </Card>
);

export default Testimonials;
